<template>
  <div v-loading="loading">
    <div
      v-if="isFieldAvailableForEdit(scope.row, 'name')"
      style="text-align: center">
      <i style="display: inline; cursor: pointer; font-size: 10px" class="fas fa-arrow-up" @click="setOrder('up')"></i>
      <i style="display: inline; cursor: pointer; font-size: 10px; margin-left: 4px" class="fas fa-arrow-down" @click="setOrder('down')"></i>

    </div>

    <div>{{order}}</div>

  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementScalePropTableColumn} from "@/mixins/elementScalePropTableColumn";
import {mapGetters} from "vuex";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-order-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},

  computed: {

    ...mapGetters('card/sections', {
      sections: 'sections',
      sectionsElements: 'sectionsElements'
    }),
    order() {
      const thisSectionType = this.scope._self.section.type;
      if( thisSectionType === 'basic' ){
        return this.scope.$index+1;
      }

      const basicSection = this.sections.find(s => s.type === 'basic');
      const basicSectionCount = basicSection ? this.sectionsElements[basicSection.id]?.length : 0;
      if( thisSectionType === 'personal_library' ){
        return (basicSectionCount+1) + '.' + (+this.scope.$index+1);
      }

      const personalLibrarySection = this.sections.find(s => s.type === 'personal_library');
      const personalLibrarySectionCount = personalLibrarySection ? this.sectionsElements[personalLibrarySection.id]?.length : 0;
      if( thisSectionType === 'personal_manual' ){
        return (basicSectionCount+1) + '.' + (personalLibrarySectionCount +this.scope.$index + 1);
      }

      const personalManualSection = this.sections.find(s => s.type === 'personal_manual');
      const personalManualSectionCount = personalManualSection ? this.sectionsElements[personalManualSection.id]?.length : 0;
      if( thisSectionType === 'quality' ){
        const firstPart = basicSectionCount + 1 + ((personalLibrarySectionCount + personalManualSectionCount) ? 1 : 0 );
        return firstPart + '.' + (+this.scope.$index + 1);
      }
      return 999;
    },
  },
  data() {
    return {
      loading: false,
    }
  },

  methods: {
    setOrder(direction){
      this.loading = true;
      this.$api.card.moveElementOrder({
        id: this.scope.row.id,
        direction: direction,
      })
        .finally(() => {
          this.loading = false;
          this.$emit('elements-changed');
        })
    }
  }
}

</script>

<style>

</style>
